<template>
    <div>我的按钮</div>
</template>

<script>
export default {
    name: 'my-button',
    data(){
        return {}
    }
}
</script>