export default {
  navBar: [
    {
      id: 1,
      title: "首页",
      icon: require("@/assets/img/index/shouye.png"),
      url: "/index",
    },
    {
      id: 2,
      title: "用户中心",
      icon: require("@/assets/img/index/usercenter.png"),
      url: "/userCenter",
    },
    {
      id: 3,
      title: "站长中心",
      icon: require("@/assets/img/index/zhanzhangguanli.png"),
      url: "/stationCenter",
    },
    {
      id: 4,
      title: "订单管理",
      icon: require("@/assets/img/index/orderguanli.png"),
      url: "/orderManage",
    },
    {
      id: 5,
      title: "财务管理",
      icon: require("@/assets/img/index/caiwuguanli.png"),
      url: "/financeManage",
    },
    {
      id: 6,
      title: "提现管理",
      icon: require("@/assets/img/index/tijianguanli.png"),
      url: "/tiXianManage",
    },
    {
      id: 7,
      title: "优惠券管理",
      icon: require("@/assets/img/index/coupon.png"),
      url: "/couponManage",
      child:[
        {
          id:7-1,
          title:"优惠券",
          icon:require("@/assets/img/index/coupon.png"),
          url:'/coupon'
        },
        {
          id:8-2,
          title:"优惠码",
          icon:require("@/assets/img/index/coupon.png"),
          url:'/couponCode'
        }
      ]
    },
    {
      id: 8,
      title: "留言管理",
      icon: require("@/assets/img/index/liuyanguanli.png"),
      url: "/messageManage",
      child:[
        {
          id:8-1,
          title:"意见反馈",
          icon:require("@/assets/img/index/liuyanguanli.png"),
          url:'/feeBackManage'
        },
        {
          id:8-2,
          title:"应聘站长",
          icon:require("@/assets/img/index/liuyanguanli.png"),
          url:'/stationFeed'
        }
      ]
    },
    {
      id: 9,
      title: "公告管理",
      icon: require("@/assets/img/index/gonggaoguanli.png"),
      url: "/noticeManage",
    },
    {
      id: 10,
      title: "问答管理",
      icon: require("@/assets/img/index/wendaguanli.png"),
      url: "/questionManage",
      child:[
        {
          id:10-1,
          title:"小程序",
          icon:require("@/assets/img/index/liuyanguanli.png"),
          url:'/smallWeChat'
        },
        {
          id:10-2,
          title:"站长H5",
          icon:require("@/assets/img/index/liuyanguanli.png"),
          url:'/stationH5'
        }
      ]
    },
    {
      id: 11,
      title: "轮播图管理",
      icon: require("@/assets/img/index/lunboguanli.png"),
      url: "/bannerManage",
    },
    {
      id: 12,
      title: "新手教程管理",
      icon: require("@/assets/img/index/xinshoujiaocheng.png"),
      url: "/noviceTutorial",
    },
    {
      id: 13,
      title: "商学院管理",
      icon: require("@/assets/img/index/shangxueyuan.png"),
      url: "/businessManage",
      // child:[
      //   {
      //     id:13-1,
      //     title:"商学院管理",
      //     icon: require("@/assets/img/index/shangxueyuan.png"),
      //     url: "/businessNewsAddArticle",
      //   }
      // ]
    },
    {
      id: 15,
      title: "商学院发布文章",
      icon: require("@/assets/img/index/shangxueyuan.png"),
      url: "/businessNewsAddArticle",
      // child:[
      //   {
      //     id:13-1,
      //     title:"商学院管理",
      //     icon: require("@/assets/img/index/shangxueyuan.png"),
      //     url: "/businessNewsAddArticle",
      //   }
      // ]
    },
    {
      id: 14,
      title: "官网新闻中心",
      icon: require("@/assets/img/index/shangxueyuan.png"),
      url: "/news",
      // child:[
      //   {
      //     id:14-1,
      //     title:"官网新闻中心",
      //     icon: require("@/assets/img/index/shangxueyuan.png"),
      //     url: "/newsAddArticle",
      //   }
      // ]
    },
    {
      id: 14,
      title: "官网新闻中心发布",
      icon: require("@/assets/img/index/shangxueyuan.png"),
      url: "/newsAddArticle",
      // child:[
      //   {
      //     id:14-1,
      //     title:"官网新闻中心",
      //     icon: require("@/assets/img/index/shangxueyuan.png"),
      //     url: "/newsAddArticle",
      //   }
      // ]
    }
  ],
  /* 订单管理--订单状态*/
  // '待支付', '待打印', '打印中', '代发货', '已发货', '已取消', '已完成'
  orderState: [
    {
      value: "999",
      label: "全部",
    },
    {
      value: "0",
      label: "待支付",
    },
    {
      value: "1",
      label: "待打印",
    },
    {
      value: "2",
      label: "打印中",
    },
    {
      value: "3",
      label: "待发货",
    },
    {
      value: "4",
      label: "已发货",
    },
    {
      value: "5",
      label: "已取消",
    },
    {
      value: "6",
      label: "已完成",
    },
  ],
  /* 订单管理--打印类型 */
  orderCategory: [
    {
      value: "999",
      label: "全部",
    },
    {
      value: "1",
      label: "文件和图片打印",
    },
    {
      value: "2",
      label: "证件照打印",
    },
    {
      value: "3",
      label: "照片冲洗",
    },
  ],
  /* 财务管理--订单类型*/
  financeOrderType: [
    {
      value: "999",
      label: "全部",
    },
    {
      value: "1",
      label: "收入",
    },
    {
      value: "-1",
      label: "支出",
    },
  ],
  /* 财务管理--订单快捷时间*/
  financeorderTime: [
    {
      value: "999",
      label: "全部",
    },
    // {
    //   value: "7",
    //   label: "最近七天",
    // },
    // {
    //   value: "15",
    //   label: "最近十五天",
    // },
    {
      value: "30",
      label: "最近三十天",
    },
  ],
  /* 优惠券--优惠券状态 */
  couponState: [
    {
      value: "999",
      label: "全部",
    },
    {
      value: "0",
      label: "禁用",
    },
    {
      value: "1",
      label: "正常",
    },
  ],
  /* 添加优惠券--优惠券类型*/
  addCouponType: [{}],

  /* 提现管理--状态 0: 未打款, 1: 已打款, 2:已驳回*/
  txState: [
    {
      value: "999",
      label: "全部",
    },
    {
      value: "1",
      label: "已打款",
    },
    {
      value: "0",
      label: "未打款",
    },
    {
      value: "2",
      label: "已驳回",
    },
  ],
  /* 提现管理--身份 zz/user*/
  txRole: [
    {
      value: "999",
      label: "全部",
    },
    {
      value: "zz",
      label: "站长",
    },
    {
      value: "user",
      label: "用户",
    },
  ],
  /* */
  /* 快递公司 */
  orderCopam: [{
    id: 1,
    name: '韵达快递'
  },
  {
    id: 2,
    name: '顺丰快递'
  },
  {
    id: 3,
    name: '申通快递'
  },
  {
    id: 4,
    name: '圆通快递'
  },
  {
    id: 5,
    name: '中通快递'
  },
  {
    id: 6,
    name: '百世汇通'
  },
  {
    id: 7,
    name: '极兔快递'
  },
  {
    id: 8,
    name: '邮政快递'
  }
],
};
