<template>
  <div class="home">
    <div id="demo2">
      <div class="" v-html="content"></div>
    </div>
    <!-- <button type="button" class="btn" @click="getEditorData">获取当前内容</button> -->
    <!-- <h3>内容预览</h3>
       <textarea name="" id="" cols="170" rows="20" readonly v-model="editorData"></textarea> -->
  </div>
</template>

<script>
import axios from 'axios'
import wangEditor from "wangeditor";
export default {
  name: "Editor2",
  // props: {
  //   content: String,
  //   edit_title: String,
  // },
  data() {
    return {
      editor: null,
      editorData: "",
      upfileData_key: "",
    };
  },
  props: ["catchData",'content','edit_title'],
  watch: {
    content() {
      console.log("父组件传递过来的值", this.content);
      // this.editor.txt.html(this.content)
    },
  },
  // props: ['catchData'], //接收父组件的方法
  mounted() {
    this.establish()

  },
  methods: {
    establish(){
      let that = this;
      const editor = new wangEditor(`#demo2`);
      // editor.config.fontSizes={
      //   'x-small':{name:'10px',value:'10px'},
      //   'xxx-large':{name:'48px',value:'48px'}
      // }

      editor.config.uploadImgServer = "https://oss-campus-print.yihuichuang.com/";
      // editor.customConfig.uploadFileName = '你自定义的文件名'
      editor.config.uploadImgParams = {
        key: this.upfileData_key,
      };
      editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        console.log("22", resultFiles);
        let date = that.util.formatDate(new Date());
        let date_ = that.util.formatTime(new Date()).replace(/,/g, "");
        let randnum = (Math.random() * 9999999999).toFixed(0);
        let fileName = date_ + randnum;
        that.upfileData_key = "upload/xcx/" + date + "/" + fileName;

        let formData = new FormData();
        formData.append("key", "upload/xcx/" + date + "/" + fileName);
        formData.append("file", resultFiles[0]);
        let configs = {
          headers: { "Content-Type": "multipart/form-data" },
        };
        axios.post("https://oss-campus-print.yihuichuang.com/", formData, configs)
          .then((res) => {
            console.log(res);
            if (res.status == 204)
              // 上传图片，返回结果，将图片插入到编辑器中
              insertImgFn(
                "https://oss-campus-print.yihuichuang.com/" + that.upfileData_key
              );
          });
      };
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        // newHtml : 富文本的内容
        this.editorData = newHtml;
        localStorage.setItem("fwb_nav", newHtml);
        this.catchData(newHtml)
        // console.log(newHtml)
      };

      // 创建编辑器
      editor.create();
      this.editor = editor;
    },
    getEditorData() {
      // 通过代码获取编辑器内容
      let data = this.editor.txt.html();
      alert(data);
    },

    beforeDestroy() {
      // 调用销毁 API 对当前编辑器实例进行销毁
      this.editor.destroy();
      this.editor = null;
    },
  },
};
</script>

<style type="text/css">
.home {
  width: 100%;
  margin: auto;
  position: relative;
}
.btn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px 10px;
  cursor: pointer;
}

.home h3 {
  margin: 30px 0 15px;
}
</style>
