import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.scss'
//引入自定义组件
import './components/global'
//引入全局自定义filters
import './filters/index'
import util from './common/common.js'
Vue.prototype.util = util
Vue.use(ElementUI)
// 引入左侧边菜单导航栏
import $conf from './common/config/config'
Vue.prototype.$conf=$conf
//VUE.js项目中控制台报错： Uncaught (in promise) NavigationDuplicated解决方法
import Router from 'vue-router'
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
