<template id="modelTab">
<el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="modelTitle" id="allModel"
    :visible.sync="showTabModel" width="30%">
    <!-- 标题 -->
    <div class="model_title flex justify-between align-center">
      <div class="flex align-center">
           <span class="text-16 text-bold text-black">{{modelTitle}}</span> 
      </div>
      <i @click="closeTabModel" class="el-icon-close" style="cursor:pointer"></i>
    </div>
    <!-- 表格内容 -->
    <div class="margin-tb-24">
        <el-table :header-cell-style="{background:'#F5F6F7',color:'#333333',fontsize:'14px'}" :data="tableList" stripe
          style="width: 100%">
          <el-table-column prop="date" align="center" label="ID">
        </el-table-column>
        <el-table-column prop="name" align="center" label="微信昵称" width="180">
        </el-table-column>
        <el-table-column prop="address" align="center" label="注册时间">
        </el-table-column>
        <el-table-column prop="address" align="center" label="TA的下级">
        </el-table-column>
        <el-table-column prop="address" align="center" label="下单数">
        </el-table-column>
        <el-table-column prop="address" align="center" label="预估收益">
        </el-table-column>
        </el-table>
    </div>

    <!-- 分页 -->
    
</el-dialog>
</template>
<script>
export default {
    name:'table-model',
    data() {
      return {
        dialogVisible: false
      };
    },
    props:{
        showTabModel: Boolean,
        modelTitle: String,
        tableList:Array
    },
    created(){
        console.log(this.tableList)
    },
    methods: {
      closeTabModel(){
          this.$emit('closeTabModel')
      }
    }
}
</script>
<style>
#allModel .el-dialog {
    border-radius: 8px;
    width: 780px !important;
    max-height: 600px;
    overflow-y: scroll;
  }
  #modelTab ::-webkit-scrollbar {
  /*隐藏滚轮*/
  /* display: none; */
  }

  #allModel .el-dialog__header {
    display: none !important;
  }

  #allModel .el-dialog__body {
    padding: 24px !important;
  }    
</style>