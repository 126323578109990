const formatTime = date =>{
	const year = date.getFullYear()
	const month = date.getMonth() + 1
	const day = date.getDate()
	const hour = date.getHours()
	const minute = date.getMinutes()
	const second = date.getSeconds()
	// return (year+month+day+hour+minute+second).toString()
	return [year,month,day].map(formatNumber)+ [hour,minute,second].map(formatNumber)
}
const formatDate = date =>{
	const year = date.getFullYear()
	const month = date.getMonth() + 1
	const day = date.getDate()

	return [year,month].map(formatNumber).join('-')
}
const formatNumber = n =>{
	n=n.toString()
	return n[1]?n:'0'+n
}

module.exports={
	formatTime: formatTime,
  formatDate: formatDate
}
