//封装全局组件
import Vue from 'vue'
const componentsContext = require.context('./',true,/\.js$/)

componentsContext.keys().forEach(component => {
    const componentConfig = componentsContext(component)
    //兼容import export 和 require module.export两种规范
    const ctrl = componentConfig.default || componentConfig
    //如果全局组件
    if(ctrl && ctrl.name){
        Vue.component(ctrl.name,ctrl)
    }
});