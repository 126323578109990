<template>
  <el-empty :image="img" :image-size="88" description='暂无数据'></el-empty>
</template>

<script>
  export default{
    name:'Empty',
    data() {
      return{
        img:require('@/assets/img/empty.png')
      }
    }
  }
</script>

<style>
</style>
