<template>

</template>
<script>
export default {
  data() {
    return {
      cur: 0, //初始化
      items: [
        require("@/assets/img/1.jpg"),
        require("@/assets/img/2.jpg"),
        require("@/assets/img/1.jpg"),
        require("@/assets/img/2.jpg"),
      ],
    };
  },
};
</script>
<style scoped></style>
