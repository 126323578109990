import Vue from 'vue'
import VueRouter from 'vue-router'
// import permission from './modules/permission'

Vue.use(VueRouter) 

const routes = [
  {
    path: '/',
    redirect:'/home'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/Login.vue')
  },
  {
    path: '/home',
    name: 'home',
    redirect:'/index',
    meta:{title:'首页',keepAlive:true},
    component: () => import( '../layout/index.vue'),
    children:[
      {
        path: '/index',
        name: 'index',
        meta:{
          title:'首页',
          keepAlive:true
        },
        component: () => import( '../views/Home/index.vue')
      },
      {
        path:'/userCenter',
        name:"userCenter",
        meta:{
          title:'用户中心',
          keepAlive:true
        },
        component:()=>import('../views/userCenter/index.vue')
      },
      {
        path:'/stationCenter',
        name:"stationCenter",
        meta:{
          title:'站长中心',
          keepAlive:true
        },
        component:()=>import('../views/stationCenter/index.vue')
      },
      {
        path:'/orderManage',
        name:"orderManage",
        meta:{
          title:'订单管理',
          keepAlive:true
        },
        component:()=>import('../views/orderManage/index.vue')
      },
      {
        path:'/orderDetail/:id',
        name:"orderDetail",
        meta:{
          title:'订单管理',
          keepAlive:true
        },
        component:()=>import('../views/orderManage/orderDetail.vue')
      },
      {
        path:'/financeManage',
        name:"financeManage",
        meta:{
          title:'财务管理',
          keepAlive:true
        },
        component:()=>import('../views/financeManage/index.vue')
      },
      {
        path:'/tiXianManage',
        name:"tiXianManage",
        meta:{
          title:'提现管理',
          keepAlive:true
        },
        component:()=>import('../views/tiXianManage/index.vue')
      },
      {
        path:'/couponManage',
        name:"couponManage",
        meta:{
          title:'优惠券管理',
          keepAlive:true
        },
        component:()=>import('../views/couponManage/index.vue'),
        redirect:'/coupon',
        children:[
          {
            path:'/coupon',
            name:"coupon",
            meta:{
              title:'优惠券',
              keepAlive:true
            },
            component:()=>import('../views/couponManage/coupon.vue'),
          },
          {
            path:'/couponCode',
            name:"couponCode",
            meta:{
              title:'优惠码',
              keepAlive:true
            },
            component:()=>import('../views/couponManage/couponCode.vue'),
          },
        ]
      },
      {
        path:'/messageManage',
        name:"messageManage",
        meta:{
          title:'留言管理',
          keepAlive:true
        },
        redirect:'/feeBackManage',
        component:()=>import('../views/messageManage/index.vue'),
        children:[
          {
            path:'/feeBackManage',
            name:"feeBackManage",
            meta:{
              title:'意见反馈',
              keepAlive:true
            },
            component:()=>import('../views/messageManage/index1.vue'),
          },
          {
            path:'/stationFeed',
            name:"stationFeed",
            meta:{
              title:'应聘站长',
              keepAlive:true
            },
            component:()=>import('../views/messageManage/index2.vue'),
          },
        ]
      },
      {
        path:'/noticeManage',
        name:"noticeManage",
        meta:{
          title:'公告管理',
          keepAlive:true
        },
        component:()=>import('../views/noticeManage/index.vue')
      },
      {
        path:'/questionManage',
        name:"questionManage",
        meta:{
          title:'问答管理',
          keepAlive:true
        },
        redirect:'/smallWeChat',
        component:()=>import('../views/questionManage/index.vue'),
        children:[
          {
            path:'/smallWeChat',
            name:"smallWeChat",
            meta:{
              title:'小程序',
              keepAlive:true
            },
            component:()=>import('../views/questionManage/smallWeChat.vue'),
          },
          {
            path:'/stationH5',
            name:"stationH5",
            meta:{
              title:'站长H5',
              keepAlive:true
            },
            component:()=>import('../views/questionManage/stationH5.vue'),
          },
        ]
      },
      {
        path:'/bannerManage',
        name:"bannerManage",
        meta:{
          title:'轮播图管理',
          keepAlive:true
        },
        component:()=>import('../views/bannerManage/index.vue')
      },
      {
        path:'/noviceTutorial',
        name:"noviceTutorial",
        meta:{
          title:'新手教程管理',
          keepAlive:true
        },
        component:()=>import('../views/noviceTutorial/index.vue')
      },
      {
        path:'/businessManage',
        name:"businessManage",
        meta:{
          title:'新手教程管理',
          keepAlive:true
        },
        component:()=>import('../views/businessManage/index.vue')
      },
      {
        path:'/news',
        name:"news",
        meta:{
          title:'官网新闻中心',
          keepAlive:true
        },
        component:()=>import('../views/newsManage/index.vue'),
      },
      {
        path:'/newsAddArticle',
        name:'news',
        meta:{
          title:'官网新闻中心',
          keepAlive:true
        },
        component:()=>import('../views/newsManage/newsAddArticle.vue')
      },
      {
        path:'/businessNewsAddArticle',
        name:'businessNewsAddArticle',
        meta:{
          title:'新手教程管理',
          keepAlive:true
        },
        component:()=>import('../views/businessManage/newsAddArticle.vue')
      }
    ]
  }
]

const router = new VueRouter({
  // mode:'history',
  linkActiveClass:'active',
  routes
})

router.beforeEach((to,from,next)=>{
  if(to.path == '/login'){
    next()
  }else {
    let token = localStorage.getItem('yhc_admin_token')
    token ? next() : next('/login')
  }
})
export default router
