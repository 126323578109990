<template>
  <div style="position: relative;">
    <div :class="typeLine" ref="chart">
    </div>
    <span v-if="datas.length=='0'" style="position: absolute;top: 50%;left: 50%;transform: translate(50%);">暂无数据</span>
  </div>
</template>

<script>
  const echarts = require("echarts");
  export default {
    name:'echartLine',
    props: {
      xData:{
        type: Array,
        default: () => []
      },
      datas: {
        type: Array,
        default: () => []
      },
      typeLine:String,
      company:String
    },
    data() {
      return {
        echartsOption: {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            // data: ['近七日收益']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.xData //X轴
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value} '+this.company //刻度标签的内容格式器，支持字符串模板和回调函数两种形式，按照自己需求设置
            },
          },
          series: [{
            type: 'line',
            stack: '总量',
            symbol:'circle',//折点样式
            symbolSize:(this.typeLine=='zhanzhang'||this.typeLine=='userNum')?12:6,//折点大小
            // itemStyle: {
            //   normal: {
            //     color: (this.typeLine=='zhanzhang'||this.typeLine=='userNum')?'#FFB634':'#FF1A2E', //折点颜色 #
            //     lineStyle: {
            //       color: (this.typeLine=='zhanzhang'||this.typeLine=='userNum')?'#667fff':'#FF5C6C' //折线颜色 #
            //     }
            //   }
            // },
            emphasis: {
              focus: 'series'
            },
            areaStyle: {
              //面积渐变色
              // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              //   offset: 0,
              //   color: (this.typeLine=='zhanzhang'||this.typeLine=='userNum')?'#7B9CFF':'#FF5C6C' //
              // }, {
              //   offset: 0.18,
              //   color: (this.typeLine=='zhanzhang'||this.typeLine=='userNum')?'#4563FF':'#FF3D50' //#
              // }, {
              //   offset: 1,
              //   color: '#FFFFFF'
              // }])
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(128, 255, 165)'
                },
                {
                  offset: 1,
                  color: 'rgb(1, 191, 236)'
                }
              ])
            },
            data: this.datas //折线点数据
          }]
        },
      }
    },
    created() {
      console.log('重新加载')
      // this.datas=
    },

    mounted() {
      // console.log('ssss',this.datas)
      this.myChart = echarts.init(this.$refs.chart); // 初始化echarts, theme为light
      this.myChart.setOption(this.echartsOption); // echarts设置初始化选项
    },
    methods: {

    }

  }
</script>

<style>
  .caiwu{
    width:1600px !important;
    height:400px !important;
  }
  .userNum{
    width:800px !important;
    height: 255px !important;
  }
  .tixian{
    width:1600px !important;
    height:400px !important;
  }
  .zhanzhang{
    width:800px !important;
    height: 255px !important;
  }
</style>
